import Dashboard from '@/dashboard/pages/Dashboard'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresMainAuth: true,
    },
  },
]
