<template>
  <div class="dashboard-table__item" @mouseleave="onMouseLeave">
    <div class="dashboard-table__item-wrapper">
      <div class="dashboard-table__cell dashboard-table__cell--order">
        <p class="u-margin-bottom-xsmall paragraph">
          <strong v-if="isOrder">{{ item.quote.id }}</strong>
          <strong v-else>{{ item.id }}</strong>
        </p>
        <p v-if="description" class="paragraph paragraph--grey">
          {{ formattedDescription }}
          <span
            v-if="hasMoreDescription && !fullDescription"
            class="dashboard-table__show-more"
            @click="showMore"
          >
            More
          </span>
          <span
            v-if="hasMoreDescription && fullDescription"
            class="dashboard-table__show-more"
            @click="hideMore"
          >
            Less
          </span>
        </p>
        <p v-else class="paragraph paragraph--light paragraph--italic">
          No description
        </p>

        <div class="dashboard-table__actions">
          <div v-if="isOrder">
            <ButtonDefault
              :round="true"
              :small="true"
              :download="true"
              :href="downloadOrderUrl"
            >
              Details
            </ButtonDefault>
          </div>
          <div v-else-if="hideRowTotal">
            <ButtonDefault
              :round="true"
              :small="true"
              @click="openTalkToEngineerModal"
            >
              Contact Us
            </ButtonDefault>
          </div>
          <div v-else-if="!isConfigurationCompleted">
            <ButtonDefault :round="true" :small="true" @click="configureQuote">
              Configure
            </ButtonDefault>
          </div>
          <div
            class="dashboard-table__item-actions"
            v-else-if="quoteRequested || quoteApproved"
          >
            <Dropdown>
              <template v-slot:button>
                <ButtonIconic
                  @click="toggleDropdown"
                  theme="grey"
                  icon="dots"
                />
              </template>
              <div
                v-if="dropdownActive"
                class="dropdown__list dropdown__list--arrow"
              >
                <div class="dropdown__item" @click="configureQuote">
                  <i class="far fa-edit"></i> Configure
                </div>
                <div class="dropdown__item" v-if="isPossibleToOrder">
                  <a class="link link--no-decoration" :href="downloadQuoteUrl">
                    <i class="fas fa-cloud-download-alt"></i> Download
                  </a>
                </div>
                <div
                  class="dropdown__item dropdown__item--danger"
                  @click="deleteQuote"
                >
                  <i class="fas fa-trash"></i> Delete
                </div>
              </div>
            </Dropdown>
            <ButtonDefault
              :round="true"
              :small="true"
              :disabled="!isPossibleToOrder"
              @click="gotoCheckout"
            >
              Order
            </ButtonDefault>
          </div>
        </div>
      </div>
      <div class="dashboard-table__cell dashboard-table__cell--parts">
        <div class="dashboard-table__thumbnail">
          <img :src="thumbnail" />
          <span
            class="dashboard-table__thumbnail-label"
            v-if="parts.length > 1"
          >
            +{{ parts.length - 1 }}
          </span>
        </div>
      </div>
      <div class="dashboard-table__cell dashboard-table__cell--date">
        <p class="paragraph paragraph--grey">
          {{ date }}
        </p>
      </div>
      <div class="dashboard-table__cell dashboard-table__cell--status">
        <div class="dashboard-table__status">
          <span class="dashboard-table__status-icon" :style="statusStyles" />
          <p
            class="paragraph paragraph--grey"
            :class="{ 'paragraph--italic': !statusColor }"
          >
            {{ status }}
          </p>
        </div>
      </div>
      <div class="dashboard-table__cell dashboard-table__cell--total">
        <p
          class="paragraph paragraph--grey"
          v-if="hideRowTotal || hidePrice || price == 0"
        >
          &mdash;
        </p>
        <p class="paragraph" v-else>{{ priceFormatted }}</p>
      </div>
      <div class="dashboard-table__cell dashboard-table__cell--actions">
        <div v-if="isOrder">
          <ButtonDefault
            :round="true"
            :small="true"
            :download="true"
            :href="downloadOrderUrl"
          >
            Details
          </ButtonDefault>
        </div>
        <div v-else-if="quoteRequested">
          <template v-if="!isConfigurationCompleted">
            <div class="dashboard-table__item-actions">
              <Dropdown>
                <template v-slot:button>
                  <ButtonIconic
                    @click="toggleDropdown"
                    theme="grey"
                    icon="dots"
                  />
                </template>
                <div
                  v-if="dropdownActive"
                  class="dropdown__list dropdown__list--arrow"
                >
                  <div class="dropdown__item">
                    <a
                      class="link link--no-decoration"
                      :href="downloadQuoteUrl"
                    >
                      <i class="fas fa-cloud-download-alt"></i> Download
                    </a>
                  </div>
                  <div
                    class="dropdown__item dropdown__item--danger"
                    @click="deleteQuote"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </div>
                </div>
              </Dropdown>
              <ButtonDefault
                :round="true"
                :small="true"
                width="14rem"
                @click="configureQuote"
              >
                Configure
              </ButtonDefault>
            </div>
          </template>
          <template v-else>
            <div class="dashboard-table__item-actions">
              <Dropdown>
                <template v-slot:button>
                  <ButtonIconic
                    @click="toggleDropdown"
                    theme="grey"
                    icon="dots"
                  />
                </template>
                <div
                  v-if="dropdownActive"
                  class="dropdown__list dropdown__list--arrow"
                >
                  <div class="dropdown__item" @click="configureQuote">
                    <i class="far fa-edit"></i> Configure
                  </div>
                  <div class="dropdown__item" v-if="isPossibleToOrder">
                    <a
                      class="link link--no-decoration"
                      :href="downloadQuoteUrl"
                    >
                      <i class="fas fa-cloud-download-alt"></i> Download
                    </a>
                  </div>
                  <div
                    class="dropdown__item dropdown__item--danger"
                    @click="deleteQuote"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </div>
                </div>
              </Dropdown>
              <ButtonDefault
                :round="true"
                :small="true"
                :disabled="!isPossibleToOrder"
                width="14rem"
                @click="gotoCheckout"
              >
                Order
              </ButtonDefault>
            </div>
          </template>
        </div>
        <div
          class="dashboard-table__item-actions"
          v-else-if="quoteApproved || quoteNoQuote"
        >
          <template v-if="quoteApproved">
            <Dropdown>
              <template v-slot:button>
                <ButtonIconic
                  @click="toggleDropdown"
                  theme="grey"
                  icon="dots"
                />
              </template>
              <div
                v-if="dropdownActive"
                class="dropdown__list dropdown__list--arrow"
              >
                <div class="dropdown__item" @click="gotoCheckout">
                  <i class="far fa-edit"></i>Order
                </div>
              </div>
            </Dropdown>
            <ButtonDefault
              v-if="isPossibleToOrder"
              :round="true"
              :small="true"
              width="14rem"
              @click="configureQuote"
            >
              Review Quote
            </ButtonDefault>
          </template>
          <template v-else>
            <Dropdown>
              <template v-slot:button>
                <ButtonIconic
                  @click="toggleDropdown"
                  theme="grey"
                  icon="dots"
                />
              </template>
              <div
                v-if="dropdownActive"
                class="dropdown__list dropdown__list--arrow"
              >
                <div class="dropdown__item" @click="configureQuote">
                  <i class="far fa-edit"></i>Review Quote
                </div>
              </div>
            </Dropdown>
            <ButtonDefault
              v-if="quoteNoQuote"
              :round="true"
              :small="true"
              width="14rem"
              @click="openTalkToEngineerModal"
            >
              Contact Us
            </ButtonDefault>
          </template>
        </div>
        <div v-else-if="hideRowTotal" class="dashboard-table__item-actions">
          <Dropdown>
            <template v-slot:button>
              <ButtonIconic @click="toggleDropdown" theme="grey" icon="dots" />
            </template>
            <div
              v-if="dropdownActive"
              class="dropdown__list dropdown__list--arrow"
            >
              <div class="dropdown__item" @click="configureQuote">
                <i class="far fa-edit"></i>Review Quote
              </div>
            </div>
          </Dropdown>
          <ButtonDefault
            :round="true"
            :small="true"
            width="14rem"
            @click="openTalkToEngineerModal"
          >
            Contact Us
          </ButtonDefault>
        </div>
        <div
          v-else-if="quoteExpired"
          class="dashboard-table__item-actions dashboard-table__item-actions__no-icon-button"
        >
          <ButtonDefault
            :round="true"
            :small="true"
            width="14rem"
            @click="configureQuote"
            >Review Quote
          </ButtonDefault>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ms from 'ms'
import { formatMoney } from '@/core/helpers/money'

import renderFailed from '@/assets/img/default_render.png'

import ButtonIconic from '@/core/components/buttons/ButtonIconic'
import ButtonDefault from '@/core/components/buttons/ButtonDefault'
import Dropdown from '@/core/components/controls/Dropdown'

export default {
  name: 'DashboardTableItem',
  components: {
    ButtonIconic,
    Dropdown,
    ButtonDefault,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateTimer: null,
      date: '',
      fullDescription: false,
      dropdownActive: false,
    }
  },
  created() {
    this.date = moment(this.item.created).fromNow()

    this.dateTimer = setInterval(() => {
      this.date = moment(this.item.created).fromNow()
    }, ms('1 minute'))
  },
  beforeUnmount() {
    if (this.dateTimer) {
      clearInterval(this.dateTimer)
      this.dateTimer = null
    }
  },
  computed: {
    priceFormatted() {
      return formatMoney(this.price)
    },
    quoteRequested() {
      return this.status === 'Quoted'
    },
    quoteApproved() {
      return this.status === 'Ready for Customer Review'
    },
    quoteNoQuote() {
      return this.status === 'Quote Reviewed & Declined'
    },
    quoteExpired() {
      return this.item.expired
    },
    isOrder() {
      return !!this.item.quote
    },
    isConfigurationCompleted() {
      return this.isOrder
        ? this.item.quote.isConfigurationCompleted
        : this.item.isConfigurationCompleted
    },
    hideRowTotal() {
      return !!(
        !this.isOrder &&
        this.item.quoteType &&
        ((this.item.quoteType.toLowerCase() === 'manual' &&
          !this.quoteApproved) ||
          this.item.quoteType.toLowerCase() === 'assisted')
      )
    },
    hidePrice() {
      // hide total price if status=Requested and one of all part.manualQuotationRequired
      let result = false
      if (!this.isOrder) {
        const manualQutationRequiredParts = this.item.parts.filter(
          part => part.manualQuotationRequired
        )
        if (this.status === 'Requested' && manualQutationRequiredParts.length) {
          result = true
        }
      }
      return result
    },
    description() {
      if (this.isOrder) {
        return this.item.quote.description
      }

      return this.item.description
    },
    formattedDescription() {
      if (this.fullDescription) {
        return this.description
      }

      if (this.hasMoreDescription) {
        return `${this.description.substring(0, 20)}...`
      }

      return this.description
    },
    hasMoreDescription() {
      return this.description.length > 20
    },
    parts() {
      let parts = this.item.parts

      if (this.isOrder) {
        parts = this.item.quote.parts
      }

      return parts
    },
    thumbnail() {
      if (this.parts.length) {
        if (this.parts[0].linkThumbnail === 'failed') {
          return renderFailed
        } else {
          return `${process.env.VUE_APP_API_URL}/${this.parts[0].linkThumbnail}/?token=${this.$store.state.auth.accessToken}`
        }
      }
      return ''
    },
    price() {
      const price = +this.item.total
      return price.toFixed(2)
    },
    isEmptyPrice() {
      return !this.item.amount || this.item.amount == 0
    },
    isPossibleToOrder() {
      return !(
        (this.isEmptyPrice && !this.quoteApproved) ||
        this.item.manualQuotationRequired ||
        this.item.isExportControlled
      )
    },

    showReviewItem() {
      let result = false
      if (this.item.currentStatus) {
        let currentStatus = this.item.currentStatus.name
        if (currentStatus !== 'Approved') {
          result = true
        }
      }
      return result
    },

    status() {
      let result = ''
      if (this.item.currentStatus) {
        switch (this.item.currentStatus.name) {
          case 'Requested':
            if (this.item.quoteType === 'Manual') {
              result = 'Manual Review Requested'
            } else {
              if (this.quoteExpired) {
                result = 'Expired'
              } else {
                result = 'Quoted'
              }
            }
            break
          case 'Approved':
            result = 'Ready for Customer Review'
            break
          case 'No Quote':
            result = 'Quote Reviewed & Declined'
            break
          default:
            result = this.item.currentStatus.name
        }
      }
      return result
    },
    statusColor() {
      switch (this.status) {
        case 'Order Placed':
          return '#9703FF'
        case 'Quoted':
        case 'Ready for Customer Review':
          return '#2FA601'
        case 'Manual Review Requested':
        case 'Assisted Quote Requested':
          return '#ffba00'
        case 'Approved':
          return '#31a59a'
        case 'Confirmed':
          return '#5cda4e'
        case 'Billed':
          return '#dadf90'
        case 'Processing':
          return '#a88eda'
        case 'Processed':
          return '#7656b2'
        case 'Shipping':
        case 'Shipped':
          return '#5cda4e'
        case 'Manual quote requested':
          return '#0ea284'
        case 'Manual quote approved':
          return '#557a56'
        case 'Building':
          return '#FFEF00'
        case 'Post-processing':
          return '#41cfeb'
        case 'Refunded':
        case 'Expired':
        case 'Quote Reviewed & Declined':
          return '#898989'
        default:
          return ''
      }
    },
    statusStyles() {
      return {
        backgroundColor: this.statusColor,
      }
    },
    downloadOrderUrl() {
      return `${process.env.VUE_APP_API_URL}/api/orders/my/${
        this.item.id
      }/download/?token=${localStorage.getItem('auth:access_token')}`
    },
    downloadQuoteUrl() {
      return `${process.env.VUE_APP_API_URL}/api/quotes/my/${
        this.item.id
      }/download/?token=${localStorage.getItem('auth:access_token')}`
    },
    quoteFileName() {
      return `quote${this.item.id}.pdf`
    },
  },
  methods: {
    async configureQuote() {
      await this.$store.dispatch('quote/deleteMinOrderFee', this.item.id)
      await this.$router.push({
        name: 'quote-summary',
        params: {
          id: this.item.id,
        },
      })
    },
    deleteQuote() {
      this.$vfm.show('quote-delete-confirmation', {
        onConfirm: async () => {
          this.$store.dispatch('dashboard/deleteQuote', this.item.id)
        },
      })
    },
    onMouseLeave() {
      this.dropdownActive = false
    },
    toggleDropdown() {
      this.dropdownActive = !this.dropdownActive
    },
    showMore() {
      this.fullDescription = true
    },
    hideMore() {
      this.fullDescription = false
    },
    gotoCheckout() {
      this.$router.push({
        name: 'quote-checkout',
        params: {
          id: this.item.id,
        },
      })
    },
    openTalkToEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
  },
}
</script>
