<template>
  <div class="dashboard-table">
    <div class="dashboard-table__elem">
      <div class="dashboard-table__heading-list">
        <div class="dashboard-table__heading dashboard-table__heading--order">
          <span class="dashboard-table__content">
            Order #
            <span
              class="sort"
              :class="{ 'sort--active': sortedById }"
              @click="sortBy('id')"
            >
              <span class="sort__arrow-up" />
              <span class="sort__arrow-down" />
            </span>
          </span>
        </div>
        <div class="dashboard-table__heading dashboard-table__heading--parts">
          Parts
        </div>
        <div class="dashboard-table__heading dashboard-table__heading--date">
          <span class="dashboard-table__content">
            Date
            <span
              class="sort"
              :class="{ 'sort--active': sortedByCreated }"
              @click="sortBy('created')"
            >
              <span class="sort__arrow-up" />
              <span class="sort__arrow-down" />
            </span>
          </span>
        </div>
        <div class="dashboard-table__heading dashboard-table__heading--status">
          <span class="dashboard-table__content">
            Status
            <span
              class="sort"
              :class="{ 'sort--active': sortedByStatus }"
              @click="sortBy('current_status__name')"
            >
              <span class="sort__arrow-up" />
              <span class="sort__arrow-down" />
            </span>
          </span>
        </div>
        <div class="dashboard-table__heading dashboard-table__heading--total">
          <span class="dashboard-table__content">
            Total
            <span
              class="sort"
              :class="{ 'sort--active': sortedByAmount }"
              @click="sortBy('amount')"
            >
              <span class="sort__arrow-up" />
              <span class="sort__arrow-down" />
            </span>
          </span>
        </div>
        <div
          class="dashboard-table__heading dashboard-table__heading--actions"
        />
      </div>
      <template v-if="items.length">
        <DashboardTableItem v-for="item in items" :item="item" :key="item.id" />
      </template>
      <template v-else>
        <div class="dashboard-table__empty">
          {{ emptyMessage }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DashboardTableItem from './DashboardTableItem'

export default {
  name: 'DashboardTable',
  components: {
    DashboardTableItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    sortByValue: {
      type: String,
      default: '',
    },
    emptyMessage: {
      type: String,
      default: "You dont't have any active items",
    },
  },
  computed: {
    sortedById() {
      return this.sortByValue.includes('id')
    },
    sortedByCreated() {
      return this.sortByValue.includes('created')
    },
    sortedByStatus() {
      return this.sortByValue.includes('current_status__name')
    },
    sortedByAmount() {
      return this.sortByValue.includes('amount')
    },
  },
  methods: {
    sortBy(field) {
      let actualField = field
      if (this.sortByValue.includes(field)) {
        if (this.sortByValue.indexOf('-') === 0) {
          actualField = actualField.replace('-', '')
        } else {
          actualField = `-${actualField}`
        }
      }
      this.$emit('sort', actualField)
    },
  },
}
</script>
