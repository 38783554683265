console.log(
  `process.env.VUE_APP_OKTA_CLIENT_ID:${process.env.VUE_APP_OKTA_CLIENT_ID}`
)
console.log(
  `process.env.VUE_APP_OKTA_ISSUER:${process.env.VUE_APP_OKTA_ISSUER}`
)
console.log(
  `process.env.VUE_APP_OKTA_REDIRECT_URI:${process.env.VUE_APP_OKTA_REDIRECT_URI}`
)
export default {
  oidc: {
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    issuer: process.env.VUE_APP_OKTA_ISSUER,
    redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: true,
    },
  },
}
