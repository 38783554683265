<template>
  <DashboardLayout :loading="loading">
    <div class="dashboard">
      <DashboardHeader />
      <div class="container">
        <div class="dashboard-content">
          <DashboardApp />
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { mapState } from 'vuex'
import { fetchAccountStats } from '@/api/accountApi'
import { fetchQuotes } from '@/api/quotesApi'
import { fetchOrders } from '@/api/ordersApi'
import {
  getFiltersFromQueryString,
  updateQueryString,
} from '@/core/utils/query'
import { handleErrors } from '@/core/helpers/error'

import DashboardHeader from '@/dashboard/components/DashboardHeader'
import DashboardLayout from '@/dashboard/layouts/DashboardLayout'
import DashboardApp from '@/dashboard/components/DashboardApp'

export default {
  name: 'Dashboard',
  components: {
    DashboardHeader,
    DashboardLayout,
    DashboardApp,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState('dashboard', {
      itemsType: state => state.itemsType,
    }),
  },
  async created() {
    try {
      const filters = getFiltersFromQueryString(this.$route.query)

      const itemsType = this.$route.params.itemsType || 'orders'

      const tasks = [fetchAccountStats()]

      if (itemsType === 'orders') {
        if ('quoteType' in filters) {
          delete filters.quoteType
          updateQueryString(this.$router, this.$route, filters)
        }
        tasks.push(fetchOrders(filters))
      } else {
        tasks.push(fetchQuotes(filters))
      }

      const response = await Promise.all(tasks)

      this.$store.commit('dashboard/SET_FILTERS', filters)
      this.$store.commit('dashboard/SET_STATS', response[0].data)
      this.$store.commit('dashboard/SET_ITEMS_TYPE', itemsType)

      this.$store.commit('dashboard/SET_ITEMS', response[1].data['results'])
      this.$store.commit('dashboard/SET_TOTAL', response[1].data.count)
      this.$store.commit('dashboard/SET_TOTAL_PAGES', response[1].data.count)
    } catch (err) {
      handleErrors(err, this.$store)
    } finally {
      this.loading = false
    }
  },
  beforeUnmount() {
    this.$store.dispatch('dashboard/reset')
  },
  watch: {
    itemsType(value, newValue) {
      if (value !== newValue) {
        updateQueryString(this.$router, this.$route, {})
        this.$store.dispatch('dashboard/fetch', value)
      }
    },
  },
}
</script>
