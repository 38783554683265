import { createStore } from 'vuex'

import auth from '@/auth/store'
import quote from '@/quote/store'
import dashboard from '@/dashboard/store'

export default createStore({
  state: {
    error: null,
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error
    },
  },
  modules: {
    auth,
    quote,
    dashboard,
  },
})
