<template>
  <div class="dashboard-actions-bar">
    <div
      class="dashboard-actions-bar__wrapper"
      :class="{ 'dashboard-actions-bar__wrapper--two-actions': !isQuotesPage }"
    >
      <div class="dashboard-actions-bar__action">
        <InputSelect
          label="Status:"
          placeholder="Select status"
          :inline="true"
          :small="true"
          :light="true"
          :model-value="selectedStatus"
          :options="statuses"
          @update:modelValue="$emit('statusChanged', $event)"
        />
      </div>
      <div class="dashboard-actions-bar__action">
        <InputSelect
          label="Date:"
          :inline="true"
          :small="true"
          :light="true"
          :model-value="selectedDate"
          :options="dates"
          @update:modelValue="$emit('dateChanged', $event)"
        />
      </div>
      <div
        class="dashboard-actions-bar__action dashboard-actions-bar__action--type"
        v-if="isQuotesPage"
      >
        <InputSelect
          label="Quote Type:"
          :inline="true"
          :small="true"
          :light="true"
          :model-value="selectedQuoteType"
          :options="quoteTypes"
          @update:modelValue="$emit('quoteTypeChanged', $event)"
        />
      </div>
    </div>
    <div class="dashboard-actions-bar__aside">
      <InputText
        v-model="searchText"
        name="search"
        :search="true"
        :small="true"
        :light="true"
        :placeholder="searchPlaceholder"
        @enter="searchAttempt"
      />
      <button class="dashboard-actions-bar__filter" @click="showMobileFilters">
        <span class="dashboard-actions-bar__filter-icon" />
        Filters
      </button>
    </div>
    <MobileFiltersModal @apply="onFiltersApply" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import InputSelect from '@/core/components/inputs/InputSelect'
import InputText from '@/core/components/inputs/InputText'
import MobileFiltersModal from '@/dashboard/components/modals/MobileFiltersModal'

export default {
  name: 'DashboardActionsBar',
  components: {
    InputSelect,
    InputText,
    MobileFiltersModal,
  },
  props: {
    searchTextValue: {
      type: String,
      default: '',
    },
    selectedStatus: {
      type: String,
      default: '',
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedQuoteType: {
      type: String,
      default: '',
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    searchText: '',
    dates: [
      {
        title: 'All time',
        value: '',
      },
      {
        title: 'Today',
        value: 'Today',
      },
      {
        title: '7 days',
        value: '7 days',
      },
      {
        title: '30 days',
        value: '30 days',
      },
      {
        title: '3 month',
        value: '3 month',
      },
    ],
    quoteTypes: [
      {
        title: 'All',
        value: '',
      },
      {
        title: 'Instant',
        value: 'instant',
      },
      {
        title: 'Assisted',
        value: 'assisted',
      },
      {
        title: 'Manual',
        value: 'manual',
      },
    ],
  }),
  computed: {
    ...mapState('dashboard', {
      itemsType: state => state.itemsType,
    }),
    searchPlaceholder() {
      return this.itemsType === 'orders' ? 'Search orders' : 'Search quotes'
    },
    isQuotesPage() {
      return this.itemsType === 'quotes'
    },
  },
  methods: {
    searchAttempt() {
      this.$emit('search', this.searchText)
    },
    showMobileFilters() {
      this.$vfm.show('mobile-filters', {
        status: this.selectedStatus,
        date: this.selectedDate,
        quoteType: this.selectedQuoteType,
        isQuotesPage: this.isQuotesPage,
        quoteTypes: this.quoteTypes,
        dates: this.dates,
        statuses: this.statuses,
      })
    },
    onFiltersApply(filters) {
      this.$emit('statusChanged', filters.status)
      this.$emit('dateChanged', filters.date)

      if (this.isQuotesPage) {
        this.$emit('quoteTypeChanged', filters.quoteType)
      }
    },
  },
  watch: {
    searchTextValue(value) {
      this.searchText = value
    },
  },
}
</script>
