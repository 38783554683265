import * as THREE from 'three'
import ThreeOrbitControls from 'three-orbit-controls'

import './loaders/stl-loader'

import gridImage from './assets/grid.png'

let renderer = new THREE.WebGLRenderer({ antialias: true })
const OrbitControls = ThreeOrbitControls(THREE)

export class CADViewer {
  constructor(params) {
    THREE.Object3D.DefaultUp.set(0, 0, 1)
    this.container = params.container

    let containerStyles = getComputedStyle(this.container)

    this.height = params.height || parseInt(containerStyles.height)
    this.width = params.width || parseFloat(containerStyles.width)

    this.backgroundColor = params.backgroundColor || '#ffffff'
    this.modelColor = params.modelColor || '#909090'
    this.scene = new THREE.Scene()
    this.light = new THREE.DirectionalLight(0xffffff)
    this.camera = new THREE.PerspectiveCamera(
      30,
      this.width / this.height,
      1,
      10000
    )

    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
    this.camera.position.set(0, 0, 0)

    this.controls = new OrbitControls(this.camera, renderer.domElement)
    this.controls.noKeys = true
    this.controls.update()

    this.controls.enableZoom = false

    this.controls.addEventListener(
      'change',
      this.handleControlsChange.bind(this)
    )

    renderer.setSize(this.width, this.height, false)
    renderer.setClearColor(this.backgroundColor, 1)

    this.light.position.set(0, 0, 2)
    this.light.position.normalize()

    this.scene.add(this.light)
    this.scene.add(this.camera)

    if (this.container.childElementCount > 0) {
      this.container.replaceChild(
        renderer.domElement,
        this.container.firstElementChild
      )
    } else {
      this.container.appendChild(renderer.domElement)
    }

    this.animate()
  }

  handleControlsChange() {
    this.light.position.x = this.camera.position.x
    this.light.position.y = this.camera.position.y
    this.light.position.z = this.camera.position.z
    this.light.position.normalize()
  }

  animate() {
    requestAnimationFrame(() => {
      this.animate()
    })
    renderer.render(this.scene, this.camera)
  }

  render(url) {
    return new Promise(resolve => {
      let textureLoader = new THREE.TextureLoader()
      textureLoader.load(gridImage, tx1 => {
        const loader = new THREE.STLLoader()
        loader.load(url, geometry => {
          const tx2 = tx1.clone()
          tx2.needsUpdate = true
          const tx3 = tx1.clone()
          tx3.needsUpdate = true

          tx1.wrapS = tx2.wrapS = tx3.wrapS = THREE.RepeatWrapping
          tx1.wrapT = tx2.wrapT = tx3.wrapT = THREE.RepeatWrapping

          tx1.repeat.set(5, 5)
          tx2.repeat.set(25, 25)
          tx3.repeat.set(125, 125)
          const mat1 = new THREE.MeshBasicMaterial({
            transparent: true,
            map: tx1,
            side: THREE.FrontSide,
          })
          const mat2 = new THREE.MeshBasicMaterial({
            transparent: true,
            map: tx2,
            side: THREE.FrontSide,
          })
          const mat3 = new THREE.MeshBasicMaterial({
            transparent: true,
            map: tx3,
            side: THREE.FrontSide,
          })

          const geo = new THREE.PlaneBufferGeometry(625, 625)
          const plane1 = new THREE.Mesh(geo, mat1)
          const plane2 = new THREE.Mesh(geo, mat2)
          const plane3 = new THREE.Mesh(geo, mat3)

          geometry.computeFaceNormals()
          geometry.computeVertexNormals()
          geometry.computeBoundingBox()
          geometry.computeBoundingSphere()
          geometry.center()

          const xDims = geometry.boundingBox.max.x - geometry.boundingBox.min.x
          const yDims = geometry.boundingBox.max.y - geometry.boundingBox.min.y
          const zDims = geometry.boundingBox.max.z - geometry.boundingBox.min.z

          plane1.translateZ(-zDims / 2)
          plane2.translateZ(-zDims / 2)
          plane3.translateZ(-zDims / 2)

          const object = new THREE.Mesh(
            geometry,
            new THREE.MeshLambertMaterial({
              color: this.modelColor,
            })
          )

          this.camera.position.set(
            0,
            Math.max(xDims * 2.5, yDims * 2.5, zDims * 2.5),
            0
          )
          this.controls.rotateUp(Math.PI / 16)
          this.controls.rotateLeft((-5 * Math.PI) / 16)
          this.controls.update()

          this.scene.add(object)
          this.scene.add(plane1)
          this.scene.add(plane2)
          this.scene.add(plane3)

          resolve()
        })
      })
    })
  }
}
