<template>
  <div class="dashboard-footer">
    <div class="dashboard-footer__select">
      <InputSelect
        :xsmall="true"
        v-model="selectedOption"
        :options="selectOptions"
        width="6rem"
      />
      <span class="dashboard-footer__text">
        Showing {{ itemsFrom }} to {{ itemsTo }} of {{ totalItems }}
        {{ itemsType }}
      </span>
    </div>
    <div class="dashboard-footer__pagination">
      <Pagination
        :current-page="currentPage"
        :total-pages="totalPages"
        @changePage="$emit('changePage', $event)"
      />
    </div>
  </div>
</template>

<script>
import InputSelect from '@/core/components/inputs/InputSelect'
import Pagination from '@/core/components/controls/Pagination'

export default {
  name: 'DashboardFooter',
  components: {
    InputSelect,
    Pagination,
  },
  props: {
    itemsType: {
      type: String,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    selectedOption: 10,
    selectOptions: [
      {
        title: '10',
        value: 10,
      },
      {
        title: '20',
        value: 20,
      },
      {
        title: '30',
        value: 30,
      },
    ],
  }),
  computed: {
    itemsFrom() {
      if (this.currentPage === 1) {
        return this.totalItems ? 1 : this.totalItems
      }
      return (this.currentPage - 1) * this.itemsPerPage + 1
    },
    itemsTo() {
      if (this.currentPage === 1) {
        if (this.itemsPerPage > this.totalItems) {
          return this.totalItems
        } else {
          return this.itemsPerPage
        }
      }

      if (this.currentPage * this.itemsPerPage > this.totalItems) {
        return this.totalItems
      } else {
        return this.currentPage * this.itemsPerPage
      }
    },
  },
  created() {
    this.selectedOption = this.itemsPerPage
  },
  watch: {
    itemsPerPage() {
      this.selectedOption = this.itemsPerPage
    },
    selectedOption(value) {
      this.$emit('perPageChanged', value)
    },
  },
}
</script>
