import { io } from 'socket.io-client'

const URL = `${process.env.VUE_APP_API_URL}`

function getSocket(options = {}) {
  const params = {
    autoConnect: false,
    upgrade: false,
    ...options,
  }
  const socket = io(URL, params)
  socket.onAny((event, ...args) => {
    console.log(event, args)
  })
  return socket
}

export default getSocket
