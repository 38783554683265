<template>
  <div class="dashboard-tabs">
    <a
      class="dashboard-tabs__tab"
      href="#"
      :class="{ 'dashboard-tabs__tab--active': itemsType === 'orders' }"
      @click.prevent="selectType('orders')"
    >
      My Orders
    </a>
    <a
      class="dashboard-tabs__tab"
      :class="{ 'dashboard-tabs__tab--active': itemsType === 'quotes' }"
      @click.prevent="selectType('quotes')"
    >
      My Quotes
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardTabs',
  computed: {
    ...mapState('dashboard', {
      itemsType: state => state.itemsType,
    }),
  },
  methods: {
    selectType(type) {
      this.$store.commit('dashboard/SET_ITEMS_TYPE', type)
    },
  },
}
</script>
