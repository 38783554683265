import queryString from 'query-string'
import moment from 'moment'

export const getFiltersFromQueryString = query => {
  const result = {}

  if (query['sort_by']) {
    result['sortBy'] = query['sort_by']
  }

  if (query['per_page'] && !isNaN(query['per_page'])) {
    result['perPage'] = parseInt(query['per_page'])
  }

  if (query['page_number'] && !isNaN(query['page_number'])) {
    result['currentPage'] = parseInt(query['page_number'])
  }

  if (query['q']) {
    result['q'] = query['q']
  }

  if (query['status']) {
    result['status'] = query['status']
  }

  if (query['date']) {
    result['date'] = query['date']
  }

  if (query['quote_type']) {
    result['quoteType'] = query['quote_type']
  }

  return result
}

export const makeQueryFilters = filters => {
  const query = {}

  if (filters['sortBy']) {
    query['sort_by'] = filters['sortBy']
  }
  if (filters['limit']) {
    query['limit'] = filters['limit']
  }
  if (filters['offset']) {
    query['offset'] = filters['offset']
  }
  if (filters['q']) {
    query['q'] = filters['q']
  }
  if (filters['status']) {
    query['status'] = filters['status']
  }

  if (filters['date']) {
    let startDate = ''
    let endDate = ''
    switch (filters['date']) {
      case 'Today':
        startDate = moment()
          .startOf('day')
          .toISOString()
        endDate = moment()
          .endOf('day')
          .toISOString()
        break
      case '7 days':
        startDate = moment()
          .subtract(7, 'days')
          .toISOString()
        endDate = moment().toISOString()
        break
      case '30 days':
        startDate = moment()
          .subtract(30, 'days')
          .toISOString()
        endDate = moment().toISOString()
        break
      case '3 month':
        startDate = moment()
          .subtract(3, 'months')
          .toISOString()
        endDate = moment().toISOString()
        break
    }

    if (startDate) {
      query['start_date'] = startDate
    }
    if (endDate) {
      query['end_date'] = endDate
    }
  }

  if (filters['quoteType']) {
    query['quote_type'] = filters['quoteType']
  }

  return queryString.stringify(query)
}
