export const getFiltersFromQueryString = query => {
  const result = {}

  if (query['sort_by']) {
    result['sortBy'] = query['sort_by']
  }

  if (query['per_page'] && !isNaN(query['per_page'])) {
    result['perPage'] = parseInt(query['per_page'])
  }

  if (query['page_number'] && !isNaN(query['page_number'])) {
    result['currentPage'] = parseInt(query['page_number'])
  }

  if (query['q']) {
    result['q'] = query['q']
  }

  if (query['status']) {
    result['status'] = query['status']
  }

  if (query['date']) {
    result['date'] = query['date']
  }

  if (query['quote_type']) {
    result['quoteType'] = query['quote_type']
  }

  return result
}

export const updateQueryString = (router, route, filters) => {
  const query = {}

  if (filters['sortBy']) {
    query['sort_by'] = filters['sortBy']
  }

  if (filters['perPage']) {
    query['per_page'] = filters['perPage']
  }

  if (filters['currentPage']) {
    query['page_number'] = filters['currentPage']
  }

  if (filters['q']) {
    query['q'] = filters['q']
  }

  if (filters['status']) {
    query['status'] = filters['status']
  }

  if (filters['date']) {
    query['date'] = filters['date']
  }

  if (filters['quoteType']) {
    query['quote_type'] = filters['quoteType']
  }

  router.push({
    name: route.name,
    query: {
      ...query,
    },
  })
}
