export class Pardot {
  static send(payload) {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'

    let src = 'https://go.fathommfg.com/l/137771/2020-09-30/2pntq8?'

    if ('emailAddress' in payload) {
      src += `email_address=${payload['emailAddress']}`
    }

    if ('userId' in payload) {
      src += `&user_id=${payload['userId']}`
    }

    if ('firstName' in payload) {
      src += `&first_name=${payload['firstName']}`
    }

    if ('lastName' in payload) {
      src += `&last_name=${payload['lastName']}`
    }

    if ('company' in payload) {
      src += `&company=${payload['company']}`
    }

    if ('phone' in payload) {
      src += `&phone=${payload['phone']}`
    }

    if ('zipcode' in payload) {
      src += `&zipcode=${payload['zipcode']}`
    }

    iframe.src = src

    document.body.appendChild(iframe)
  }
}
