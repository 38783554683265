<template>
  <div class="dashboard-header">
    <div class="container">
      <div class="dashboard-header__wrapper">
        <div class="dashboard-header__figure" />

        <div class="dashboard-header__container">
          <div class="dashboard-header__content">
            <h1 class="dashboard-header__top-title">SmartQuote Dashboard</h1>
            <h4 class="dashboard-header__more-info">More info</h4>
            <div v-if="isLoggedIn" class="dashboard-header__title-list">
              <h4 class="dashboard-header__title">
                Welcome, {{ `${user.firstName} ${user.lastName}` }}!
              </h4>
              <small class="dashboard-header__company-name">
                {{ user.company }}
              </small>
            </div>
            <div class="dashboard-header__statuses">
              <div class="dashboard-header__status">
                <div class="dashboard-status-block">
                  <h4
                    class="dashboard-status-block__title"
                    @click="gotoMyOrders"
                  >
                    Active orders
                  </h4>
                  <p class="dashboard-status-block__text">
                    <strong class="dashboard-status-block__strong">{{
                      activeOrders
                    }}</strong>
                    / {{ totalOrders }}
                  </p>
                </div>
              </div>
              <div class="dashboard-header__status">
                <div class="dashboard-status-block">
                  <h4
                    class="dashboard-status-block__title"
                    @click="gotoMyQuotes"
                  >
                    Active quotes
                  </h4>
                  <p class="dashboard-status-block__text">
                    <strong class="dashboard-status-block__strong">{{
                      activeQuotes
                    }}</strong>
                    / {{ totalQuotes }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="dashboard-header__aside">
            <div class="dashboard-header__aside-btn u-flex-right">
              <ButtonPrimary @click="openInstantQuote" width="25rem">
                Start a Quote
              </ButtonPrimary>
            </div>
            <div class="dashboard-header__aside-content">
              <div class="u-margin-bottom-medium u-text-right">
                <p class="u-margin-bottom-xxsmall paragraph paragraph--light">
                  <strong class="u-margin-right-small">
                    Designing for manufacturability?
                  </strong>
                  <a
                    class="link link--primary"
                    @click.prevent="openTalkToEngineerModal"
                    href="#"
                  >
                    <strong>Talk To An Engineer</strong>
                  </a>
                </p>
                <small class="small">
                  <strong>
                    35+ years of engineering expertise available to you at the
                    click of the button.
                  </strong>
                </small>
                <div class="u-margin-top-medium">
                  <nav class="promo-box__nav promo-box__nav--right">
                    <ul class="promo-box__nav-list promo-box__nav-list--right">
                      <li
                        class="promo-box__nav-item"
                        v-click-outside="hideDropdown"
                      >
                        <Dropdown>
                          <a
                            class="link link--primary link--no-decoration"
                            href="#"
                            @click.prevent="toggleDropdown"
                          >
                            Helpful Resources
                            <i class="fas fa-chevron-down"></i>
                          </a>
                          <div
                            v-if="dropdownOpened"
                            class="dropdown__list dropdown__list--top dropdown__list--wide"
                          >
                            <div class="dropdown__item">
                              <a
                                class="link link--no-decoration"
                                target="_blank"
                                href="https://fathommfg.com/overview"
                              >
                                3D Printing Technologies
                              </a>
                            </div>
                            <div class="dropdown__item">
                              <a
                                class="link link--no-decoration"
                                target="_blank"
                                href="https://fathommfg.com/3d-printing-materials"
                              >
                                3D Printing Materials
                              </a>
                            </div>
                            <div class="dropdown__item">
                              <a
                                class="link link--no-decoration"
                                target="_blank"
                                href="https://fathommfg.com/smartquote-resources"
                              >
                                Knowledge Center
                              </a>
                            </div>
                            <div class="dropdown__item">
                              <a
                                class="link link--no-decoration"
                                target="_blank"
                                href="https://fathommfg.com/videos"
                              >
                                Featured Videos
                              </a>
                            </div>
                          </div>
                        </Dropdown>
                      </li>
                      <li class="promo-box__nav-item">
                        <a
                          class="promo-box__nav-link"
                          target="_blank"
                          href="https://fathommfg.com/smartquote-faq"
                        >
                          FAQs
                        </a>
                      </li>
                      <li class="promo-box__nav-item">
                        <a
                          class="promo-box__nav-link"
                          href="#"
                          @click="openTalkToEngineerModal"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dashboard-header__emblem" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ClickOutside from '@/core/directives/click-outside'

import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import Dropdown from '@/core/components/controls/Dropdown'
export default {
  name: 'DashboardHeader',
  directives: {
    ClickOutside,
  },
  components: {
    ButtonPrimary,
    Dropdown,
  },
  computed: {
    ...mapGetters('auth', ['user', 'isLoggedIn']),
    ...mapGetters('dashboard', [
      'activeOrders',
      'totalOrders',
      'activeQuotes',
      'totalQuotes',
    ]),
  },
  data() {
    return {
      dropdownOpened: false,
    }
  },
  methods: {
    hideDropdown() {
      this.dropdownOpened = false
    },
    toggleDropdown() {
      this.dropdownOpened = !this.dropdownOpened
    },
    gotoMyOrders() {
      this.$store.commit('dashboard/SET_ITEMS_TYPE', 'orders')
    },
    gotoMyQuotes() {
      this.$store.commit('dashboard/SET_ITEMS_TYPE', 'quotes')
    },
    openInstantQuote() {
      this.$router.push({
        name: 'main',
        params: {
          isFromDashboard: true,
        },
      })
    },
    openTalkToEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
  },
}
</script>
