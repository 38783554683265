<template>
  <div class="dashboard-layout">
    <transition name="fade" appear mode="out-in">
      <AppLoader v-if="loading" />
    </transition>
    <div class="dashboard-layout__content">
      <div class="dashboard-layout__header">
        <AppHeader :show-logo="true" />
        <AppTopbar />
      </div>
      <main class="dashboard-layout__main">
        <slot />
      </main>
      <div class="dashboard-layout__footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/core/components/layout/AppHeader'
import AppFooter from '@/core/components/layout/AppFooter'
import AppLoader from '@/core/components/layout/AppLoader'
import AppTopbar from '@/core/components/layout/AppTopbar'

export default {
  name: 'DashboardLayout',
  components: {
    AppHeader,
    AppFooter,
    AppLoader,
    AppTopbar,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
