export default {
  activeOrders(state) {
    return state.activeOrders
  },
  totalOrders(state) {
    return state.totalOrders
  },
  activeQuotes(state) {
    return state.activeQuotes
  },
  totalQuotes(state) {
    return state.totalQuotes
  },
  statusesOptions(state) {
    let result = []
    let total = 0

    if (state.stats && state.itemsType) {
      const statuses = state.stats[state.itemsType]
      Object.keys(statuses).forEach(status => {
        let statusCount = 0
        const groupItems = []
        Object.keys(statuses[status]).forEach(key => {
          statusCount += statuses[status][key]
          groupItems.push({
            title: `${key} (${statuses[status][key]})`,
            grouped: true,
            value: key,
          })
        })
        result.push({
          title: `${status.charAt(0).toUpperCase()}${status.substring(
            1
          )} (${statusCount})`,
          value: status,
        })
        total += statusCount
        result = result.concat(groupItems)
      })
    }

    result.unshift({
      title: `All (${total})`,
      value: '',
    })

    return result
  },
}
