<template>
  <div id="top" class="dashboard-app">
    <transition name="fade" appear mode="out-in">
      <div v-if="itemsFetching" class="dashboard-app__loading">
        <AppSpinner />
      </div>
    </transition>
    <DashboardTabs />
    <DashboardActionsBar
      :search-text-value="searchText"
      :selected-status="selectedStatus"
      :selected-date="selectedDate"
      :selected-quote-type="selectedQuoteType"
      :statuses="statusesOptions"
      @search="onSearch"
      @statusChanged="onStatusChanged"
      @dateChanged="onDateChanged"
      @quoteTypeChanged="onQuoteTypeChanged"
    />
    <div class="dashboard-wrapper">
      <div class="dashboard-table-wrapper">
        <DashboardTable
          :sort-by-value="sortBy"
          :empty-message="emptyMessage"
          :items="items"
          :loading="itemsFetching"
          @sort="onSort"
        />
      </div>
    </div>
    <DashboardFooter
      :items-type="itemsType"
      :total-items="total"
      :items-per-page="perPage"
      :current-page="currentPage"
      :total-pages="totalPages"
      @perPageChanged="onPerPageChanged"
      @changePage="onChangePage"
    />

    <QuoteDeleteConfirmationModal />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { updateQueryString } from '@/core/utils/query'

import DashboardTabs from '@/dashboard/components/DashboardTabs'
import DashboardActionsBar from '@/dashboard/components/DashboardActionsBar'
import DashboardFooter from '@/dashboard/components/DashboardFooter'
import DashboardTable from '@/dashboard/components/dashboard-table/DashboardTable'
import AppSpinner from '@/core/components/content/AppSpinner'
import QuoteDeleteConfirmationModal from '@/dashboard/components/modals/QuoteDeleteConfirmationModal'

export default {
  name: 'DashboardWrapper',
  components: {
    QuoteDeleteConfirmationModal,
    DashboardTabs,
    DashboardActionsBar,
    DashboardFooter,
    DashboardTable,
    AppSpinner,
  },
  computed: {
    ...mapState('dashboard', {
      items: state => state.items,
      itemsType: state => state.itemsType,
      itemsFetching: state => state.itemsFetching,
      total: state => state.total,
      perPage: state => state.perPage,
      currentPage: state => state.currentPage,
      totalPages: state => state.totalPages,
      sortBy: state => state.sortBy,
      searchText: state => state.searchText,
      selectedStatus: state => state.selectedStatus,
      selectedDate: state => state.selectedDate,
      selectedQuoteType: state => state.selectedQuoteType,
    }),
    ...mapGetters('dashboard', ['statusesOptions']),
    emptyMessage() {
      return `You don't have any active ${this.itemsType}`
    },
  },
  methods: {
    updateQueryString() {
      updateQueryString(this.$router, this.$route, {
        q: this.searchText,
        perPage: this.perPage,
        currentPage: this.currentPage,
        status: this.selectedStatus,
        sortBy: this.sortBy,
        date: this.selectedDate,
        quoteType: this.selectedQuoteType,
      })
    },
    async onSort(sortBy) {
      this.$store.commit('dashboard/SET_SORT_BY', sortBy)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
      } catch (err) {
        console.log(err.message)
      }
    },
    async onPerPageChanged(perPage) {
      this.$store.commit('dashboard/SET_PER_PAGE', perPage)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
        this.$scrollTo('#top')
      } catch (err) {
        console.log(err.message)
      }
    },
    async onChangePage(currentPage) {
      this.$store.commit('dashboard/SET_CURRENT_PAGE', currentPage)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
        this.$scrollTo('#top')
      } catch (err) {
        console.log(err.message)
      }
    },
    async onStatusChanged(status) {
      this.$store.commit('dashboard/SET_SELECTED_STATUS', status)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
      } catch (err) {
        console.log(err.message)
      }
    },
    async onDateChanged(date) {
      this.$store.commit('dashboard/SET_SELECTED_DATE', date)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
      } catch (err) {
        console.log(err.message)
      }
    },
    async onSearch(searchText) {
      this.$store.commit('dashboard/SET_SEARCH_TEXT', searchText)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
      } catch (err) {
        console.log(err.message)
      }
    },
    async onQuoteTypeChanged(quoteType) {
      this.$store.commit('dashboard/SET_QUOTE_TYPE', quoteType)

      this.updateQueryString()

      try {
        await this.$store.dispatch('dashboard/fetch', this.itemsType)
      } catch (err) {
        console.log(err.message)
      }
    },
  },
}
</script>
