export const FileStatuses = {
  UPLOADING: 0,
  UPLOADED: 1,
  ERROR: 2,
}

export const TechnologyTypes = {
  Printing: 1,
  CNCMachining: 5,
  UrethaneCasting: 6,
  InjectionMolding: 7,
  MultipleProcesses: 10,
  SheetMetal: 12,
}

export const PrintingTechnology = [1, 2, 3, 4, 8, 9, 11]

export const imSecondaryOperations = [
  {
    title: 'EMI Paint',
    name: 'emiPaint',
    value: false,
  },
  {
    title: 'Paint',
    name: 'paint',
    value: false,
  },
  {
    title: 'Heatstake Inserts',
    name: 'heatstakeInserts',
    value: false,
  },
  {
    title: 'Ultrasonic Welding',
    name: 'ultrasonicWelding',
    value: false,
  },
  {
    title: 'Assembly',
    name: 'assembly',
    value: false,
  },
  {
    title: 'Pad Printing',
    name: 'padPrinting',
    value: false,
  },
  {
    title: 'Post Tapping',
    name: 'postTapping',
    value: false,
  },
  {
    title: 'Other',
    name: 'other',
    value: false,
  },
]

export const smFinishes = [
  {
    title: 'Heat Treating',
    name: 'heatTreating',
    value: false,
  },
  {
    title: 'Anodizing',
    name: 'anodizing',
    value: false,
  },
  {
    title: 'Plating',
    name: 'plating',
    value: false,
  },
  {
    title: 'Passivation',
    name: 'passivation',
    value: false,
  },
  {
    title: 'Powder Coating',
    name: 'powderCoating',
    value: false,
  },
  {
    title: 'Paint',
    name: 'paint',
    value: false,
  },
  {
    title: 'Other (text)',
    name: 'other',
    value: false,
  },
]

export const smCertifications = [
  {
    title: 'Material',
    name: 'material',
    value: false,
  },
  {
    title: 'Heat Treating',
    name: 'heatTreating',
    value: false,
  },
  {
    title: 'Certificate of Conformance',
    name: 'cofc',
    value: false,
  },
  {
    title: 'Plating',
    name: 'plating',
    value: false,
  },
]

export const smToleranceRequirements = [
  {
    title: 'Standard (+/- 0.005")',
    value: 'standard',
  },
  {
    title: 'Specified',
    value: 'specified',
  },
]

export const smThreads = [
  {
    title: 'No',
    value: 'no',
  },
  {
    title: 'Yes',
    value: 'yes',
  },
]

export const smQuality = [
  {
    title:
      'Basic (Inspected for accuracy as built.  No documentation provided)',
    value: 'basic',
  },
  {
    title: 'Standard FAI (1-3 part inspection of critical dims)',
    value: 'standard',
  },
  {
    title: 'Other - please specify (text field)',
    value: 'other',
  },
]

export const CNCOtherMaterial = 114

export const extDimensionRequired = ['stl', 'obj', '3dm']
