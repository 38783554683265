import { fetchQuotes, deleteQuote } from '@/api/quotesApi'
import { fetchOrders } from '@/api/ordersApi'

export default {
  async fetch({ commit, state }, type) {
    if (state.itemsFetching) return

    commit('SET_ITEMS_FETCHING', true)

    try {
      let apiCall = null
      const params = {
        sortBy: state.sortBy,
        limit: state.perPage,
        offset: (state.currentPage - 1) * state.perPage,
        q: state.searchText,
        status: state.selectedStatus,
        date: state.selectedDate,
      }
      if (type === 'orders') {
        apiCall = fetchOrders
      } else {
        apiCall = fetchQuotes
        params['quoteType'] = state.selectedQuoteType
      }

      const { data } = await apiCall(params)

      commit('SET_ITEMS', data['results'])
      commit('SET_TOTAL', data.count)
      commit('SET_TOTAL_PAGES', data.count)
    } catch (err) {
      console.log(err.message)
    } finally {
      commit('SET_ITEMS_FETCHING', false)
    }
  },
  async deleteQuote({ commit }, quoteId) {
    await deleteQuote(quoteId)

    commit('DELETE_ITEM', quoteId)
  },
  reset({ commit }) {
    commit('RESET')
  },
}
