import { INITIAL_STATE } from '@/dashboard/store/state'

export default {
  SET_STATS(state, stats) {
    state.activeQuotes = 0
    Object.values(stats.quotes.active).forEach(
      count => (state.activeQuotes += count)
    )
    state.totalQuotes = state.activeQuotes + stats.quotes.expired

    state.activeOrders = 0
    Object.values(stats.orders.active).forEach(
      count => (state.activeOrders += count)
    )
    state.totalOrders = state.activeOrders
    Object.values(stats.orders.inactive).forEach(
      count => (state.totalOrders += count)
    )

    state.stats = stats
  },
  SET_ITEMS_TYPE(state, itemsType) {
    state.itemsType = itemsType
  },
  SET_ITEMS_FETCHING(state, itemsFetching) {
    state.itemsFetching = itemsFetching
  },
  SET_QUOTE_TYPE(state, quoteType) {
    state.selectedQuoteType = quoteType
  },
  SET_ITEMS(state, items) {
    state.items = [...items]
  },
  DELETE_ITEM(state, itemId) {
    const index = state.items.findIndex(item => item.id === itemId)
    if (index !== -1) {
      state.items.splice(index, 1)
    }
  },
  SET_SELECTED_STATUS(state, selectedStatus) {
    state.selectedStatus = selectedStatus
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage
  },
  SET_TOTAL_PAGES(state, count) {
    let totalPages = 1
    if (count && state.perPage) {
      totalPages = Math.ceil(count / state.perPage)
    }
    state.totalPages = totalPages
  },
  SET_SEARCH_TEXT(state, searchText) {
    state.searchText = searchText
  },
  SET_SELECTED_DATE(state, selectedDate) {
    state.selectedDate = selectedDate
  },
  SET_FILTERS(state, filters) {
    state.perPage = filters.perPage || 10
    state.currentPage = filters.currentPage || 1
    state.sortBy = filters.sortBy || ''
    state.searchText = filters.q || ''
    state.selectedStatus = filters.status || ''
    state.selectedDate = filters.date || ''
    state.selectedQuoteType = filters.quoteType || ''
  },
  RESET(state) {
    Object.keys(INITIAL_STATE).forEach(key => {
      state[key] = INITIAL_STATE[key]
    })
  },
  SELECT_TYPE(state, type) {
    state.selectedType = type
  },
}
