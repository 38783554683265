<template>
  <AppModal
    name="mobile-filters"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('mobile-filters')"
  >
    <div class="mobile-filters">
      <div class="mobile-filters__row">
        <InputSelect
          label="Status"
          placeholder="Select status"
          v-model="status"
          :options="statuses"
        />
      </div>
      <div class="mobile-filters__row">
        <InputSelect label="Date" v-model="date" :options="dates" />
      </div>
      <div v-if="isQuotesPage" class="mobile-filters__row">
        <InputSelect
          label="Quote Type"
          v-model="quoteType"
          :options="quoteTypes"
        />
      </div>
      <div class="mobile-filters__action">
        <ButtonBase @click="$vfm.hide('mobile-filters')">
          Close
        </ButtonBase>
        <ButtonPrimary width="20rem" @click="apply">
          OK
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import InputSelect from '@/core/components/inputs/InputSelect'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import ButtonBase from '@/core/components/buttons/ButtonBase'
export default {
  name: 'MobileFilterModal',
  components: {
    AppModal,
    InputSelect,
    ButtonPrimary,
    ButtonBase,
  },
  data() {
    return {
      status: '',
      date: null,
      quoteType: '',
      isQuotesPage: false,
      quoteTypes: [],
      dates: [],
      statuses: [],
    }
  },
  methods: {
    onBeforeOpen(e) {
      this.status = e.ref.params.value.status
      this.date = e.ref.params.value.date
      this.quoteType = e.ref.params.value.quoteType
      this.isQuotesPage = e.ref.params.value.isQuotesPage
      this.quoteTypes = e.ref.params.value.quoteTypes
      this.dates = e.ref.params.value.dates
      this.statuses = e.ref.params.value.statuses
    },
    onClosed() {},
    apply() {
      this.$emit('apply', {
        status: this.status,
        date: this.date,
        quoteType: this.quoteType,
      })
      this.$vfm.hide('mobile-filters')
    },
  },
}
</script>
