export const INITIAL_STATE = {
  items: [],
  total: 0,
  sortBy: '',
  searchText: '',
  currentPage: 1,
  perPage: 10,
  totalPages: 1,
  selectedStatus: '',
  stats: null,
  itemsType: 'orders',
  selectedDate: '',
  selectedQuoteType: '',

  totalOrders: 0,
  activeOrders: 0,
  totalQuotes: 0,
  activeQuotes: 0,

  itemsFetching: false,
}

export default {
  ...INITIAL_STATE,
}
