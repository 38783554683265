import VueFinalModal from 'vue-final-modal'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import store from '@/core/store'
import router from '@/core/router'

import validator from '@/core/plugins/validator'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import oktaConfig from './okta-config'
const oktaAuth = new OktaAuth(oktaConfig.oidc)

export default app => {
  app.use(store)
  app.use(router)
  app.use(validator)

  app.use(VueFinalModal())
  app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
  app.use(OktaVue, { oktaAuth })
}
